#body {
  background-color: rgb(238, 238, 238);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: rgb(51, 51, 51);
  min-height: 100% !important;
  height: 100% !important;
}
* {
  font-family: 'Open Sans', sans-serif;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

.background-color {
  background-color: rgb(51, 51, 51);
}

.full_height {
  height: 100%;
}

.overflowy_scroll {
  overflow-y: scroll;
}

.admin_table >tr:nth-child(even) {
  background-color: #D6EEEE;
}

.drop_wrapper_as_button {
  overflow: hidden;
  background-color: #ffc107 !important;
  height: 37px !important;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  width: auto !important;
}

.drop_wrapper {
  margin: 5px;
  border: 1px dashed #000;
}

.spread_details > .popover {
  max-width: 700px !important;
}

.org_popover > .popover {
  min-width: 350px !important;
  max-width: 700px !important;
}

.not_selectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

#popup_email_preview_wrapper .popup-content {
  width: auto !important;
}

.no_select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.validation_box {
  border-radius: 6px;
  border: 1px solid lightgrey;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;
  margin: 4px;
  padding-left: 8px;
  padding-right: 0px;
  width: 36px;
  height: 42px;
  font-size: 32px;
  box-sizing: border-box;
  color: black;
  background-color: white;
}

.selected {
  background-color: rgb(51, 51, 51);
  color: #fff;
}

.additional_details > div {
  border: 1px solid black;
  text-align: center;
}

.caret_wrapper {
  width: 15px;
  height: 15px;
}

#candidate_sort {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.selected_candidate_sort {
  background-color: #add8e6;
  display: flex;
  flex-direction: row;
}

#candidate_sort > div {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
}

.fontawesome_shadow {
  text-shadow: 0 0 3px #000;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.visible {
  visibility: visible !important;
  opacity: 1 !important;
}

.box-row-1 {
  grid-row: 1;
  align-items: center;
  padding-bottom: 5px;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-row-2 {
  grid-row: 2;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
}

.box-row-3 {
  grid-row: 3;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
}

.like-box-1 {
  grid-column: 1;
}

.like-box-2 {
  grid-column: 2;
}

.like-box-3 {
  grid-column: 3;
}

.like-box-4 {
  grid-column: 4;
}

.like-box-5 {
  grid-column: 5;
}

#candidate_sort > div > h6 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.like-wrapper {
  width: 100%;
  display: grid;
}

.bold {
  font-weight: 700;
}

.center_text {
  text-align: center;
}

.box {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
}

.no-text-decoration {
  text-decoration: none;
}

.no-text-decoration:hover {
  text-decoration: none;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.clickable {
  color: blue;
  cursor: pointer;
}

.no-atag-blue {
  color: inherit;
}

#evaluation-table-wrapper {
  height: 100%;
}

#evaluation-table-wrapper > .table-responsive > table {
  height: 100%;
}
/* #evaluation-table-wrapper > .table-responsive > table > tbody > tr > td {
  border: 10px solid blue !important;
} */
#evaluation-container {
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 10px solid black; */
}

.disable-tab {
  background-color: #707070 !important;
  color: #e8e8e8 !important;
}

.progress-bar {
  height: 10px;
  width: 280px;
  max-width: 100%;
  border: 1px solid #000000;
  background: none !important;
}

.progress-bar > div {
  width: 0%;
  height: 100%;
  background-color: #228b22;
}

.no-atag-blue:hover {
  color: inherit;
}

.pointer {
  cursor: pointer;
}

.two {
  /* max-height: calc(100% - 50px); */
}
#candidate_table_with_filters > th > div {
  width: 20px;
}

#candidate_table_with_filters > th {
  position: sticky;
  top: 0;
  cursor: pointer;
  background-color: #fff;
  white-space: nowrap;
}

.red_staging {
  padding: 5px;
  border-radius: 10px;
  background-color: transparent;
  color: black;
  -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s;
}

.vl {
  border-left: 1px solid #000;
  height: 100%;
}

.red {
  background-color: #ffcccc !important;
}

.browser_display {
  display: inline;
}

.mobile_display {
  display: none;
}

@media only screen and (max-width: 600px) {
  #header_wrapper {
    min-height: 60px !important;
  }

  #header_wrapper > div {
    min-height: 60px !important;
  }
  #logo_title {
    font-size: 0.9em !important;
  }
  .logowrapper {
    height: 42px;
  }
  .logowrapper > img {
    height: 10px !important;
    width: 10px !important;
    margin-bottom: 10px !important;
  }

  .browser_display {
    display: none !important;
  }

  .mobile_display {
    display: inline !important;
  }
}

.icon-outline {
  stroke: #000;
  stroke-width: 25px;
}

.longest-button-min-width {
  min-width: 176px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.transition {
  -webkit-transition: all 2s; /* Safari prior 6.1 */
  transition: all 2s;
}

.slide {
  background-color: #fff !important;
}

.blue-hover:hover {
  background-color: #1167b1 !important;
  color: #fff !important;
}

.light-blue-border-hover:hover {
  border: 6px solid #30cde5 !important;
}

.light-blue-border-hover {
  -webkit-transition: border 100ms linear;
  -ms-transition: border 100ms linear;
  transition: border 100ms linear;
}

.icon_wrapper:hover .font_awesome_icon {
  color: #1167a5 !important;
}

.color_red {
  color: red;
}

/* .draggable_item > div > p {
  height: 3px;
} */

/* .draggable_item > div {
  height: 60px;
} */

/* .draggable_item > div > div {
  margin-left: 72px;
  height: 50px;
  font-size: 0.6em;
} */

#container_wrapper {
  padding-left: 15px;
  padding-right: 15px;
}
#drag_and_drop_overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  background-color: black;
}

.selectedTab {
  /* background-color: #262626; */
  /* border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black; */
  border-bottom: none !important;
  background-color: #fff;
  color: rgb(51, 51, 51);
}

.selectedButton {
  background-color: #1167b1;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  /* background-color: #fff; */
  color: #fff;
}

.unselectedTab {
  background-color: #d8d8d8;
  border-bottom: 1px solid #000000;
  color: #262626;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.midwrapper {
  vertical-align: middle;
  padding: 10px;
  margin-bottom: 10px;
}

.vertical_align_middle {
  vertical-align: middle !important;
}
.midblock {
  display: inline-block;
  vertical-align: middle;
}

#coordinator_atendee_wrapper {
  padding: 15px;
}

#coordinator_atendee_table {
  table-layout: fixed;
}

#coordinator_atendee_table th {
  background-color: #000000;
  color: #ffffff;
  text-align: center;
}

.faux_a_tag {
  color: #0000ee;
  cursor: pointer;
  text-decoration: underline;
}

.table-row-test > td {
  /* min-width: 1800px; */
}

.admin_popover > .popover {
  max-width: 400px !important;
  width: 400px !important
}

.admin_popover label {
  word-wrap: normal !important;
}

.alert {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.popup_edit_schedule-overlay > div {
  width: auto !important;
  padding: 15px imp !important;
}

.hover_blue:hover {
  background-color: #c1e1ec !important;
  color: #000000 !important;
  cursor: pointer !important;
}

.blue_selected {
  background-color: #c1e1ec !important;
  
}

.hover_blue {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.font_awesome_icon {
  -webkit-transition: color 200ms linear;
  -ms-transition: color 200ms linear;
  transition: color 200ms linear;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.loading_overlay {
  height: 100%;
  width:100%;
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);

}

.dashed_top_line > td {
  padding-top: 0;
  padding-bottom: 0;
  /* border-top: 1px dashed black; */
}

.preview-inputs {
  background-color: #fff !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.black-border-bottom {
  border-bottom: 1px solid #000;
}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-error-text-only {
  color: #a94442;
}

.carousel-notifications {
  /* max-width: 700px; */
}

.carousel-notifications > div {
  border: 1px solid #000;

  max-width: 700px;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.no-margin {
  margin: 0 !important;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.messageout-enter {
  opacity: 0;
}
.messageout-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.messageout-exit {
  opacity: 1;
}
.messageout-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.centered-image {
  display: block;
  margin: 0 auto;
}

.candidate_sort_selected {
  background-color: #707070 !important;
  color: #ffffff;
}

.candidate_sort_selected:hover {
  color: #000000 !important;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.no_border {
  border: none !important;
}

.no_padding {
  padding: 0 !important;
}

.no_margin {
  margin: 0 !important;
}

.passing_wrapper > td {
  padding-top: 0;
  padding-bottom: 0 !important;
}

.stripes {
  cursor: default !important;
  background: repeating-linear-gradient(40deg, #f2f2f2, #f2f2f2 10px, #ffffff 10px, #ffffff 20px) !important;

  /* background: repeating-linear-gradient(-55deg, #610003, #610003 10px, #616161 10px, #616161 20px) !important; */
  /* background: repeating-linear-gradient(-55deg, #222, #222 10px, #333 10px, #333 20px) !important; */
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.cross-fade-height {
  /* transition: height 0.5s ease-in-out; */
}

@media only screen and (max-width: 800px) {
  .carousel-notifications > div {
    max-width: 95vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .h2_mobile {
    font-size: 25px;
  }
  .h5_mobile {
    font-size: 15px;
  }
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.dashed_top_line > td {
  /* border-top: 1.5px dashed #000000; */
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.frame::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.display_none {
  display: none !important;
}

/* toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
