.candidateListTableHeader {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
}

.header_z_index {
  z-index: 3;
}

.filter {
  min-width: 250px;
  height: 340px;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
}
/* th:after,
th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

th:before {
  top: -1px;
  border-top: 1px solid lightgray;
}


th:after {
  bottom: -1px;
  border-bottom: 2px solid lightgray;

} */