.settingsPopover {
  min-width: 750px;
}

.seasonPopover {
  min-width: 750px;
  height: 400px;
}

.cross-fade-leave {
  opacity: 1;
}

.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 5s ease-in;
}

.cross-fade-enter {
  opacity: 0;
}

.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 5s ease-in;
}

.cross-fade-height {
  transition: height 0.5s ease-in-out;
}

.testKey {
  font-size: 50px;
}
