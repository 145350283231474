.headerDetailPopover {
  min-width: 320px;
}

.addColumnPopover {
  min-width: 340px;
}

.addTimeSlotPopover {
  min-width: 340px;
  max-height: 200px;
}

/*vertical align does not work in react in-line styling it seems*/
.timeSlot {
  vertical-align: middle !important;
}

.timeSlot:hover {
  background-color: burlywood;
}

.timeSlotHeader {
  min-width: 340px;
  height: 210px;
}

.timeSlotHeader :hover {
  background-color: burlywood;
}
.customEvent {
  min-width: 750px;
}

.customDuration {
  min-width: 450px;
}

.tableClassName {
  height: 150px;
}

.unavailableEvaluatorPicker {
  min-width: 250px;
}
