.demo-wrapper {
  position: relative;
  z-index: 10;
  margin: 0;
  background-color: rgb(51, 122, 183);
  border-style: solid;
  border-color: rgb(51, 51, 51);
  -webkit-transition: top 500ms ease-out, left 500ms ease-out, border-color 500ms ease-out;
  -moz-transition: top 500ms ease-out, left 500ms ease-out, border-color 500ms ease-out;
  -o-transition: top 500ms ease-out, left 500ms ease-out, border-color 500ms ease-out;
  transition: top 500ms ease-out, left 500ms ease-out, border-color 500ms ease-out;
}

.bubble {
  -webkit-transition: opacity 500ms ease-out;
  -moz-transition: opacity 500ms ease-out;
  -o-transition: opacity 500ms ease-out;
  transition: opacity 500ms ease-out;
}

.demo-background {
  -webkit-filter: brightness(40%); /* Safari 6.0 - 9.0 */
  filter: brightness(40%);
}
