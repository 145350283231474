img .rankImage {
  width: 80px;
  height: 80px;
}

.rankImage:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #242933;
  z-index: 0;
  background-color: darkgrey;
}

.rankImage:after {
  content: 'Missing ' attr(alt);
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
