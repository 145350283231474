.importPopover {
  min-width: 250px;
  height: 200px;
}

.manageFieldsPopover {
  min-width: 1100px;
  height: 430px;
}

.documentTypesPopover {
  min-width: 450px;
  height: 350px;
}

.markPopover {
  min-width: 280px;
  min-height: 100px;
}

.preScreenPopover {
  min-width: 250px;
  min-height: 200px;
}

.widgetMarkPopover {
  min-width: 450px;
}
