.editorField {
  /* max-height:  calc(100vh - 680px); */
  /* min-height: 300px; calc(100vh - 680px); */
  background-color: white;
  border-top-color: rgb(241, 241, 241) !important;
  border-top-style: solid !important;
  border-top-width: 1px !important;
  border-right-color: rgb(241, 241, 241) !important;
  border-right-style: solid !important;
  border-right-width: 1px !important;
  border-bottom-color: rgb(241, 241, 241) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-left-color: rgb(241, 241, 241) !important;
  border-left-style: solid !important;
  border-left-width: 1px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 2px !important;
  padding-top: 10px !important;
  height: calc(100vh - 630px); /*570 if width more than 1380px*/
  min-height: 150px;
  overflow-y: auto;
}

@media screen and (min-width: 1380px) {
  .editorField {
    height: calc(100vh - 570px);
  }
}

.toolbar {
  position: sticky;
  top: 0;
  background-color: #eeeeee;
  z-index: 5;
  padding-bottom: 10;
}

.wrapper {
  background-color: #ffffff;
}

.rdw-link-decorator-wrapper a {
  text-decoration: underline !important;
}
/* Forces Enter newlines to have zero top and bot padding. */
.public-DraftStyleDefault-block {
  position: relative;
  /* background-color: aqua; */
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 0;
}

.public-DraftStyleDefault-ol {
  margin-top: 0;
  margin-bottom: 0;
}

.public-DraftStyleDefault-ul {
  margin-top: 0;
  margin-bottom: 0;
}