.roomNumberPopover {
  min-width: 330px;
  min-height: 150px;
}

.focusPopover {
  min-width: 200px;
  min-height: 300px;
}

.unavailablePopover {
  min-width: 750px;
  min-height: 450px;
}

.roomPropertiesPopover {
  min-width: 550px;
  min-height: 350px;
}
