.popperClassName {
  min-width: 300px;
}
.printPopOverClassName {
  min-width: 380px;
  z-index: 4;
}
.settingsPopOverClassName {
  min-width: 550px;
  min-height: 200px;
}
